!
<template>
  <div>
    <a-modal :visible="visiable" @ok="rebackRecord" @cancel="handleCancel">
      <div class="row">
        <img src="@/assets/images/timeline/warn-icon.png" />
        <span
          >将此待办标记为未完成您所添加的记录内容将会被清除并且此操作无法撤消建议您新建一个待办；您确认将待办标记为未完成吗？</span
        >
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch } from 'vue';
import { message } from 'ant-design-vue';
import timelineApi from '@/api/timeline';

const props = defineProps({
  visiable: Boolean,
  userData: Object,
});

const id = ref('');
const createrId = ref('');

const emits = defineEmits(['closeModal']);

const handleCancel = () => {
  emits('closeModal', false);
};

//删除指定记录
const rebackRecord = () => {
  timelineApi
    .rebackRecordList('', {
      id: id.value,
      userId: createrId.value,
      completed: 'false',
    })
    .then(res => {
      console.log(res);
      if (res) {
        message.success('回退成功');
        emits('closeModal', true);
      }
    });
};

watch(
  () => props.userData,
  (newVal, oldVal) => {
    id.value = newVal.id;
    createrId.value = newVal.createUser;
  }
);
</script>

<style lang="less" scoped>
.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;

  img {
    width: 21px;
    height: 21px;
    object-fit: cover;
  }

  span {
    width: 384px;
    color: #3d3d3d;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}
</style>
